import Date from '../components/date'
import React from 'react';
import {Link} from "gatsby";

export default function PostPreview({
                                        title,
                                        date,
                                        slug,
                                        firmName,
                                        category
                                    }) {
    return (
        <div className={'border-b border-accent-2 mt-4'}>
            <h3 className="text-3xl mb-3 leading-snug text-gray-700">
                <Link to={`/${category}/${slug}`} className="hover:underline">
                    {category === 'notices' && <span>FCA issues a {title} to {firmName}</span>}
                    {category === 'warnings' && <span>{title}</span>}
                </Link>
            </h3>
            <p className="text-lg leading-relaxed mb-4 text-gray-600">
                {category === 'notices' && <span>On <Date dateString={date}/>, the Financial Conduct Authority issued a {title} to {firmName}.&nbsp;</span>}
                {category === 'warnings' && <span>On <Date dateString={date}/>, the Financial Conduct Authority issued a warning regarding the {title}.&nbsp;</span>}
                <Link to={`/${category}/${slug}`} className="hover:underline text-blue-600">
                    read more
                </Link>
            </p>
        </div>
    )
}
